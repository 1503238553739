/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

var bgImage = "assets/images/hero/corporate-team.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="preview"
                    title="Our Vision"
                    description="Pioneering transformative digital solutions, our vision is to be at the forefront
                    of shaping a technologically empowered Pacific. Through ingenuity and innovation, we aspire to
                    redefine the digital landscape, providing smart and forward-thinking solutions that propel
                    businesses and communities towards a future of unprecedented possibilities."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="task"
                    title="Our Mission"
                    description="
                    Empowering businesses in the Pacific region through cutting-edge software solutions that enhance
                    efficiency, foster innovation, and drive sustainable growth. Our mission is to deliver reliable,
                    user-centric, and adaptable digital products tailored to meet the unique needs of our clients,
                    enabling them to thrive in the ever-evolving technological landscape"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="info"
                    title="About Us"
                    description="
                    All Stack Solutions started merely as an idea to promote digital awareness in the pacific, a
                    group of colleagues collectively came together to facilitate the development and deployment of 
                    applications to the wider public in the region with a huge focus on security, performance,
                    and accessibility. While we have a young and fresh mindset in the world of software development, we
                    offer new skills, a passion for creativity and a renewed appeal for pioneered technologies. We are
                    a indy software company, aiming to introduce to the pacific to a new digital era. We work remotely 
                    and swiftly."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={`${process.env.PUBLIC_URL}/${bgImage}`}
              title="Lets Get In Touch"
              description="We look forward to working with you, you can reach us via email or phone by clicking the below link, more contact methods are also available."
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "Contact Us",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
