/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/service/software.jpg";
import post2 from "assets/images/service/social-media.jpg";
import post3 from "assets/images/service/graphic-design.jpg";
import post4 from "assets/images/service/marketing.jpg";
import post5 from "assets/images/service/consult.jpg";
import post6 from "assets/images/service/data-analytics.jpg";
import post7 from "assets/images/service/cloud.jpg";

import bgImage from "assets/images/banner/technology-banner6.gif";

function Places() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Check out our Services
          </MKTypography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Software Development"
              description="Development ranging from web, mobile to core applications to suit your unique business needs"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Social Media"
              description="Businesses require various social media platforms to stay connected, we specialize in creating and maintaining social media pages for various platforms"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Graphics Design"
              description="Business cards, logo designing, and various media assets, we've got you covered"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post4}
              title="Digital Marketing"
              description="We publish your product on the web and social media, provding fully fledged advertisement campaigns"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post5}
              title="Consultancy Solutions"
              description="We provide various levels of consultancy to architect software solutions, talk to us first, we brainstorm, review code, suggest frameworks and design system architectures"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post6}
              title="Data Analytics"
              description="We review your data, fetch trends and run analytics to suit your unique needs"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post7}
              title="Cloud Services"
              description="We provide cloud platforms to easily store, access and process data and application"
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={bgImage}
              title="Its an Invasion"
              description="Your company may not be in the software business, but eventually, a software company will be in your business."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
