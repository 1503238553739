/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const BASE_URL = process.env.PUBLIC_URL;
const imagesPrefix = BASE_URL + "/assets/images";

export default [
  {
    image: `${imagesPrefix}/hero/technology-2.jpg`,
    title: "Technology",
    description:
      "We excel in using the latest technologies to create cutting-edge software solutions – you can trust us to provide you with the very best!",
    action: {
      type: "internal", // or "external" depending on your use case
      route: "/pages/landing-pages/about-us",
      color: "primary", // or any other valid color
      label: "Learn More",
    },
  },
  {
    image: `${imagesPrefix}/hero/support.jpg`,
    title: "Support",
    description:
      "We don't leave you stranded, our service package involves extensive user training and periodic system maintenance to keep you smooth sailing..",
    action: {
      type: "internal", // or "external" depending on your use case
      route: "/pages/landing-pages/about-us",
      color: "primary", // or any other valid color
      label: "Learn More",
    },
  },
  {
    image: `${imagesPrefix}/hero/experienced-2.jpg`,
    title: "Experience",
    description:
      "We are masters of our craft and have successfully deployed solutions in the pacific region",
    action: {
      type: "internal", // or "external" depending on your use case
      route: "/pages/landing-pages/about-us",
      color: "primary", // or any other valid color
      label: "Learn More",
    },
  },
  {
    image: `${imagesPrefix}/hero/pacific.jpg`,
    title: "Pacific",
    description:
      "We are a pacific island software company, fostering technological advancement in the region, support the growth of local expertise, and invest in solutions that cater specifically to the unique needs of the south pacific.",
    action: {
      type: "internal", // or "external" depending on your use case
      route: "/pages/landing-pages/about-us",
      color: "primary", // or any other valid color
      label: "Learn More",
    },
  },
];
