// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/allstackcopy.png";
// import Instagram from "@mui/icons-material/Instagram";

// const baseUrl = window.location.origin.toString;

const date = new Date().getFullYear();

export default {
  brand: {
    name: "AllStack",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/AllStackSolutions",
    },
    {
      icon: <GoogleIcon />,
      link: "mailto:allstacksolutionsfj@gmail.com",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/allstacksolutions?utm_source=qr&igsh=OGU0MmVlOWVjOQ%3D%3D",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/all-stack-solutions",
    },
  ],
  menus: [
    {
      name: "Quick Links",
      items: [
        { name: "about us", route: "/pages/landing-pages/about-us" },
        { name: "our services", route: "/pages/landing-pages/our-services" },
        { name: "our works", href: "https://github.com/all-stack-solutions" },
      ],
    },
    {
      name: "Message",
      items: [
        { name: "contact us", route: "/pages/landing-pages/contact-us" },
        // { name: "scope of works", href: "https://www.creative-tim.com/bits" },
        // { name: "project charter", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    {
      name: "Phone",
      items: [
        // { name: "contact us", route: "/pages/landing-pages/contact-us" },
        // { name: "email", href: "mailto:allstacksolutionsfj@gmail.com" },
        { name: "+6799308932", href: "tel:+6799308932" },
        { name: "+6797014015", href: "tel:+6797014015" },
      ],
    },
    {
      name: "Mail",
      items: [
        { name: "allstacksolutionsfj@gmail.com", href: "mailto:allstacksolutionsfj@gmail.com" },
        // { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
        // { name: "privacy policy", href: "https://www.creative-tim.com/privacy" },
        // { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        All Stack Solutions
      </MKTypography>
      .
    </MKTypography>
  ),
};
