/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

import MKTypography from "components/MKTypography";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={3} my={3}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    We
                    <br />
                    Provide
                  </>
                }
                description="More than just software development"
              />
              <RotatingCardBack
                image={bgBack}
                title="Our Dedication"
                description="You will save a lot of time when you opt for our services, we conduction effective planning, ensure quick development cycles and an extensive testing process."
                // action={{
                //   type: "internal",
                //   route: "/sections/page-sections/page-headers",
                //   label: "start with header",
                // }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <MKTypography
              variant="h2"
              fontWeight="bold"
              my={2}
              mx="auto"
              py={3}
              sx={{
                "@media screen and (max-width: 600px)": {
                  display: "block",
                  textAlign: "center",
                },
                "@media screen and (min-width: 601px)": {
                  display: "none",
                },
              }}
            >
              Our Offers
            </MKTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TransparentBlogCard
                  image={`${process.env.PUBLIC_URL}/assets/images/information/documentation.jpg`}
                  title="Documentation"
                  description="Explore a comprehensive service package featuring detailed user documentation and extensive training for users and future developers. The handover process encompasses a spectrum of documents, spanning planning, requirements, design, and testing. Dive into user-friendly guides and training manuals for a seamless transition to proficient software usage and development."
                  action={{
                    type: "internal",
                    route: "#",
                    label: "none",
                    color: "inherit",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TransparentBlogCard
                  image={`${process.env.PUBLIC_URL}/assets/images/information/qualityassurance.jpg`}
                  title="Quality Assurance"
                  description="
                  Quality is at the heart of what we do. Our comprehensive quality assurance includes rigorous testing, code reviews, and inspections throughout the development process. Trust us to deliver software that meets the highest standards, ensuring your satisfaction every step of the way."
                  action={{
                    type: "internal",
                    route: "#",
                    label: "none",
                    color: "inherit",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <TransparentBlogCard
                  image={`${process.env.PUBLIC_URL}/assets/images/information/consultancy.jpg`}
                  title="Consultancy"
                  description="Unlock the full potential of your projects with our consultancy services. We provide expert guidance tailored to your specific needs. Whether it's strategic planning, process optimization, or technology implementation, our team of experienced consultants is here to drive your success. Collaborate with us for insightful solutions that propel your business forward."
                  action={{
                    type: "internal",
                    route: "#",
                    label: "none",
                    color: "inherit",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TransparentBlogCard
                  image={`${process.env.PUBLIC_URL}/assets/images/information/cloud.jpg`}
                  title="Cloud Deployment"
                  description="Elevate your software experience with our cloud deployment expertise. Every application we develop finds its home in the cloud, ensuring scalability, accessibility, and seamless performance. Experience the flexibility and reliability of cloud hosting as we streamline your software deployment, making it effortlessly accessible anytime, anywhere. Your applications, our cloud – a perfect partnership for modern, efficient, and dynamic solutions."
                  action={{
                    type: "internal",
                    route: "#",
                    label: "none",
                    color: "inherit",
                  }}
                />
                {/* <DefaultInfoCard
                  icon="cloud"
                  title="Cloud Deployment"
                  description="We offer performance, scalability and reliability by using cloud services."
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
