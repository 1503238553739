/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "examples/MKAlert";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import React, { useState } from "react";
// import Recaptcha from "react-recaptcha";
// import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import axios from "axios";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    content: "",
  });

  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [isVerified, setIsVerified] = useState(false);

  const handleVerify = () => {
    setIsVerified(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://allstackcore.vercel.app/mail/send_email/", null, {
        params: formData,
      });
      setAlert({
        show: true,
        type: "success",
        message: "We've got your message and will reach you shortly via email!",
      });
      setFormData({
        name: "",
        email: "",
        subject: "",
        content: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      // Log the error message from the server if available
      if (error.response && error.response.data) {
        console.error("Server Error:", error.response.data);
      }
      setAlert({
        show: true,
        type: "error",
        message: "Error sending email. Please try again later.",
      });
    }
  };

  const handleAlertClose = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further queries you can directly reach out to us on &nbsp;
                <a href="mailto:allstacksolutionsfj@gmail.com">allstacksolutionsfj@gmail.com</a> or
                call us directly on our <a href="tel:+6799308932">phone</a>
              </MKTypography>
              <form onSubmit={handleSubmit}>
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        variant="standard"
                        label="Full Name"
                        placeholder="Example: John Doe"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        variant="standard"
                        label="Email"
                        placeholder="Be precise here"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        variant="standard"
                        label="Let us know what your message is about"
                        placeholder="Give your message a simple title"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        type="text"
                        name="content"
                        value={formData.content}
                        onChange={handleChange}
                        variant="standard"
                        label="How can we be of service?"
                        placeholder="Describe your problem statement with a brief message"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <ReCAPTCHA
                      sitekey="6LdvIK0pAAAAAAVUcp0n3z-a3Gljl0iFWMEVtFcN"
                      onChange={handleVerify}
                    />
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton
                      type="submit"
                      variant="gradient"
                      disabled={!isVerified}
                      color="info"
                      onClick={handleSubmit}
                    >
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </form>
            </MKBox>
            {alert.show && (
              <MKAlert
                type={alert.type}
                message={alert.message}
                onClose={handleAlertClose}
                sx={{ width: "80%", my: 3, mx: "auto", p: 2, mt: -3 }}
              />
            )}
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default ContactUs;
