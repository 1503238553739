import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";

const MKAlert = ({ type, message, onClose }) => {
  return (
    <Alert severity={type} onClose={onClose} sx={{ mb: 2 }}>
      {message}
    </Alert>
  );
};

MKAlert.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MKAlert;
