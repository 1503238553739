/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
var team1 = process.env.PUBLIC_URL + "/assets/images/developers/suvneet.jpg";
var team2 = process.env.PUBLIC_URL + "/assets/images/developers/atesh.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Development Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Introducing the formidable &quot;A-Team&quot; – a collective of passionate individuals
              committed to relentless self-improvement and dedicated to delivering nothing short of
              excellence. We thrive on the continuous pursuit of excellence, pushing the boundaries
              to bring forth the finest projects for our clients. Our unwavering commitment to
              innovation, collaboration, and excellence sets us apart, ensuring that every endeavor
              reflects the pinnacle of our collective capabilities. Together, we embark on a journey
              of perpetual growth and accomplishment, driven by the shared goal of exceeding client
              expectations and shaping the future of digital solutions. Welcome to a team where
              challenges become opportunities and where the extraordinary is our standard.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Suvneet Kumar"
                position={{ color: "info", label: "Developer" }}
                description="Forging the Future of Technology, Crafting Innovation through Code."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Atesh Ram"
                position={{ color: "info", label: "Founder" }}
                description="Empowering Innovation, One Line of Code at a Time."
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="#"
                position={{ color: "info", label: "Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
