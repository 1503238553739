/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
// import Service from "pages/Presentation/components/Service";

// import ExampleCard from "../components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// import bgImage from "assets/images/banner/technology-banner.png";

function Pages() {
  const renderData = data.map(({ image, title, description, action }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={title}>
      <Link to={action.route}>
        {/* <ExampleCard
          image={image}
          name={name}
          description={description}
          display="grid"
          minHeight="auto"
        /> */}
        <BackgroundBlogCard image={image} title={title} description={description} action={action} />
      </Link>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            my: 0,
            mx: "auto",
            px: 0.5,
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.light.main, 0.8),
                rgba(gradients.info.state, 0.8)
              )}, `,
          }}
          // sx={{
          //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          //     `${linearGradient(
          //       rgba(gradients.light.main, 0.8),
          //       rgba(gradients.info.state, 0.8)
          //     )}, url(${bgImage})`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          //   borderRadius: 5,
          // }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="we are the best"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Why All Stack Solutions?
          </MKTypography>
          <MKTypography variant="body1" color="text">
            We perform full requirements analysis, investigate your creative idea
            <br /> and offer the best solution for your business.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                We design the best mobile and web solutions
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                We specialize in developing tailored software solutions by thoroughly understanding
                our clients goals, studying their company processes, and leveraging the expertise of
                our team with a collective 7 years experience in mobile and web app development to
                bridge the technological gap and promote growth in the Pacific region.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
